import React from 'react';
import PropTypes from 'prop-types';
import UrlLink from '../UrlLink';
import Collapse from '../Collapse';
import Config from '../../../config';
import Button from 'gooten-components/src/components/shared/Button';

class FormCard extends React.Component {
  render() {
    return (
      <div id={'FormCard-' + this.props.id} className="card">
        <FormCardContent {...this.props} />
      </div>
    );
  }
}

class FormCardContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCollapseComponent: false
    };
  }

  handleCollapseToggle = (e, value) => {
    this.setState({ ...this.state, openCollapseComponent: value });
  };

  render() {
    const data = this.props.data;
    const actionWidgets = [];
    const textAlign = {
      textAlign: 'center',
      paddingTop: '1.5em',
      fontSize: '16px',
      fontWeight: 'bold'
    };
    const cardParagraphs = data.text.map((line, i) => {
      return <p key={i}>{line}</p>;
    });
    const cbhSettings = Config.get('cbhSettings').toJS();

    for (var i = 0; i < data.actions.length; i++) {
      if (data.actions[i].type.toLowerCase() === 'button') {
        const btnUrl = data.actions[i].url;
        const analyticsId = data.actions[i].analyticsId;
        actionWidgets.push(
          <div className="button-container" key={data.integration + 'Button-' + i}>
            <a
              className="button-enabled"
              href={data.actions[i].url}
              key={data.integration + 'Button-' + i}
              onClick={() => this.props.handleAnalytics(analyticsId, 'Clicked', btnUrl)}
            >
              <Button className="button-primary large full-width">{data.actions[i].text}</Button>
            </a>
          </div>
        );
      }

      if (data.actions[i].type.toLowerCase() === 'link') {
        actionWidgets.push(
          <span style={textAlign} key={'actionWidgets-span-' + i}>
            <UrlLink
              data={data.actions[i]}
              handleAnalytics={this.props.handleAnalytics}
              key={'UrlLink-' + i}
            />
          </span>
        );
      }
    }

    const margin = { paddingLeft: '35px', paddingRight: '15px' };
    const padding = { paddingBottom: '20px' };
    const paragraphPadding = { paddingRight: '35px' };

    return (
      <div className="card-content">
        <Collapse
          open={!this.props.stores || this.state.openCollapseComponent}
          checked={this.props.stores}
          smallTitle
          title={data.title}
          onToggle={this.handleCollapseToggle}
        >
          <div className="flex-container-column" style={margin}>
            <div className="flex-container" style={paragraphPadding}>
              <div>
                {cardParagraphs}
                <br />
              </div>
            </div>
            <div className="flex-container flex-wrap">
              <div className="flex-container-column flex-self-align-end" style={padding}>
                {actionWidgets}
              </div>
              {cbhSettings.Images.showSloth && (
                <div className="flex-inline-container flex-no-wrap flex-align-self-end">
                  <div className="border-block-img pull-right">
                    <div className={'img-responsive ' + data.img} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

FormCard.propTypes = {
  id: PropTypes.any
};

FormCardContent.propTypes = {
  data: PropTypes.object,
  stores: PropTypes.any,
  handleAnalytics: PropTypes.func
};

export default FormCard;
