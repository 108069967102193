import { all, put, select, takeLatest, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { providersSelector } from '../atoms/ProvidersTab/ProvidersTabSelectors';
import { setError, DATA_READY } from '../ProductPublishActions';
import { CHANGE_PROVIDER_STATE } from '../atoms/ProvidersSelectionPanel/ProvidersSelectionPanelActions';
import { mockupsSelector } from '../atoms/Mockups/MockupsSelectors';
import * as etsy from '../ProviderForms/EtsyPublishForm';
import * as shopify from '../ProviderForms/ShopifyPublishForm';
import * as woo from '../ProviderForms/WooPublishForm';
import * as bigcommerce from '../ProviderForms/BigCommercePublishForm';
import Rules from './ValidationRules';
import { DUPLICATE_CHANGES } from '../atoms/shared/DuplicateChanges/DuplicateChangesAction';
import { removeProductOption } from '../atoms/shared/ProductOptions/ProductOptionsActions';
import { publishDataSelector } from '../ProductPublishSelectors';

const checkProductName = (errors, provider, propsRules) => {
  errors.push({
    valid: Rules.productName.maxLengthExceeded(
      provider.get('productName'),
      propsRules.productName.maxLength
    ),
    section: 'ProductName'
  });
  errors.push({
    valid: Rules.productName.isNameEmpty(provider.get('productName')),
    section: 'ProductName'
  });
};

const checkProductTags = (errors, provider, propsRules) => {
  errors.push({
    valid: !Rules.productTags.allowNewTags(
      provider.get('selectedTags').size,
      propsRules.productTags.maxNumberOfTags
    ),
    section: 'ProductTags'
  });
  errors.push({
    valid: Rules.productTags.tagLengthExceeded(
      provider.get('selectedTags'),
      propsRules.productTags.maxTagLength
    ),
    section: 'ProductTags'
  });
};

const checkProductCollections = (errors, provider, propsRules) => {
  errors.push({
    valid: Rules.productCollections.collectionLengthExceeded(
      provider.get('selectedCollections'),
      propsRules.productCollections.maxCollectionLength
    ),
    section: 'ProductCollections'
  });
};

const checkProductVariants = (errors, provider, propsRules) => {
  errors.push({
    valid: Rules.productVariants.numberOfSkusExceeded(
      provider.get('variants').size,
      propsRules.productVariants.maxNumberOfSkus
    ),
    section: 'ProductVariants'
  });
  errors.push({
    valid: Rules.productVariants.anySkuEmpty(provider.get('variants')),
    section: 'ProductVariants'
  });
};

const checkDuplicatedSkus = (errors, provider, propsRules) => {
  errors.push({
    valid: Rules.productVariants.duplicatedSkus(provider.get('variants')),
    section: 'ProductVariants'
  });
};

function* checkMockups(errors, propsRules) {
  const mockups = yield select(mockupsSelector);

  errors.push({
    valid: Rules.mockups.numberOfMockupsExceeded(
      mockups.size,
      propsRules.mockups.maxNumberOfImages
    ),
    section: 'Mockups'
  });
}

// check for max number of selected options
function* checkProductOptions(store, rulesProvider) {
  let optionsSize = store.get('selectedOptions').size;
  let maxOptions = rulesProvider.validationRules.productOptions.maxNumberOfOptions || 999;

  for (let i = optionsSize; i > maxOptions; i--) {
    yield put(
      removeProductOption({
        storeId: store.get('id'),
        optionId: store.getIn(['selectedOptions', i - 1, 'id'])
      })
    );
  }
}

function* checkShopifyValidation(provider) {
  const propsRules = shopify.validationRules;
  const errors = [];

  yield call(checkMockups, errors, propsRules);
  yield call(checkProductName, errors, provider, propsRules);
  yield call(checkProductTags, errors, provider, propsRules);
  yield call(checkProductCollections, errors, provider, propsRules);
  yield call(checkProductVariants, errors, provider, propsRules);

  yield call(dispatchErrors, errors, provider);
}

function* checkEtsyValidation(provider) {
  const propsRules = etsy.validationRules;
  const errors = [];

  // product name
  yield call(checkProductName, errors, provider, propsRules);
  errors.push({
    valid: !Rules.productName.isRegexValid(
      propsRules.productName.regex,
      provider.get('productName')
    ),
    section: 'ProductName'
  });

  // product description
  errors.push({
    valid: Rules.productDescription.isDescriptionRequired(
      propsRules.productDescription.required,
      provider.get('productDesc')
    ),
    section: 'ProductDescription'
  });

  yield call(checkMockups, errors, propsRules);
  yield call(checkProductTags, errors, provider, propsRules);
  yield call(checkProductVariants, errors, provider, propsRules);
  yield call(checkDuplicatedSkus, errors, provider, propsRules);

  yield call(dispatchErrors, errors, provider);
}

function* checkWooValidation(provider) {
  const propsRules = woo.validationRules;
  const errors = [];

  yield call(checkProductCollections, errors, provider, propsRules);
  yield call(checkProductVariants, errors, provider, propsRules);
  yield call(checkDuplicatedSkus, errors, provider, propsRules);

  yield call(dispatchErrors, errors, provider);
}

function* checkBigCommerceValidation(provider) {
  const propsRules = bigcommerce.validationRules;
  const errors = [];

  yield call(checkMockups, errors, propsRules);
  yield call(checkProductName, errors, provider, propsRules);
  yield call(checkProductVariants, errors, provider, propsRules);

  yield call(dispatchErrors, errors, provider);
}

function* dispatchErrors(errors, provider) {
  for (let err of errors) {
    if (err.valid) {
      yield put(
        setError({ storeId: provider.get('id'), section: err.section, message: 'has_error' })
      );
    }
  }
}

function* providerStateChangeHandler() {
  yield call(delay, 300);
  const providers = yield select(providersSelector);

  for (let p of providers) {
    switch (p.get('provider')) {
      case 'shopify':
        yield call(checkShopifyValidation, p);
        break;

      case 'etsy':
        yield call(checkEtsyValidation, p);
        break;

      case 'woocommerce':
        yield call(checkWooValidation, p);
        break;

      case 'bigcommerce':
        yield call(checkBigCommerceValidation, p);
        break;

      default:
        break;
    }
  }
}

// watch when publish data is ready, and go through stores to verify publish conditions
function* dataReadyChangeHandler() {
  const publishData = yield select(publishDataSelector);

  for (const store of publishData.get('stores')) {
    switch (store.get('provider')) {
      case 'shopify':
        yield call(checkProductOptions, store, shopify);
        break;

      case 'etsy':
        yield call(checkProductOptions, store, etsy);
        break;

      case 'woocommerce':
        yield call(checkProductOptions, store, woo);
        break;

      case 'bigcommerce':
        yield call(checkProductOptions, store, bigcommerce);
        break;
    }
  }
}

function* watchProviderStateChange() {
  yield takeLatest([CHANGE_PROVIDER_STATE, DUPLICATE_CHANGES], providerStateChangeHandler);
}

function* watchDataReadyChange() {
  yield takeLatest([DATA_READY], dataReadyChangeHandler);
}

export default function* rootSaga() {
  yield all([watchProviderStateChange(), watchDataReadyChange()]);
}
