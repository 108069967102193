// NOTE: This CDN backed by AWS Lambda Img Resizer
// The resizer function can redirect to source CDN if resizer params omitted
const BASE_CDN = 'd3d6kbc7r9kabj.cloudfront.net';

const OUR_CDNS = [
  BASE_CDN,
  'cdn.print.io',
  'app-imgs.print.io',
  'imgsrv.print.io/images',
  'appassets.azureedge.net',
  'az412349.vo.msecnd.net',
  'az412349.cdn.gooten.com',
  'az795372.vo.msecnd.net',
  'az795372.cdn.gooten.com',
  'az795974.vo.msecnd.net',
  'az795974.cdn.gooten.com',
  'az504546.vo.msecnd.net',
  'az504546.cdn.gooten.com',
  'imgr.azureedge.net',
  'img-manip-resize.azureedge.net',
  'gtnimgmanipstor.blob.core.windows.net',
  'gtnimgmanipcdn.azureedge.net',
  'gtnimgmanipstorv2.blob.core.windows.net',
  'gtn-imgmanip-v2-cdn.azureedge.net',
  'printmee-imgsrv.cloudapp.net/images',
  'printio-widget-live.s3.amazonaws.com',
  'printio-widget-dev.s3.amazonaws.com'
];

const isOurCdn = url => {
  if (!url) {
    return false;
  }
  for (let i = 0; i < OUR_CDNS.length; i++) {
    const curCdn = OUR_CDNS[i];
    if (url.indexOf(curCdn) > -1) {
      return true;
    }
  }
  return false;
};

const sanitizeCDNUrl = url => {
  if (!isOurCdn(url)) {
    return url;
  }
  return url
      .split('?')[0]
      .replace('http://', 'https://')
      .replace(/ /gi, '%20')
      .replace(':443', '')
      .replace(':80', '')
      .replace(/:(?!\/\/)/gi, '')
      .replace('.vo.msecnd.net', '.cdn.gooten.com')
      // Old image resizer
      .replace('az795974.cdn.gooten.com/cdn/printio-widget-live', `${BASE_CDN}/widget-live`)
      .replace('az795974.cdn.gooten.com/cdn/printio-widget-dev', `${BASE_CDN}/widget-dev`)
      .replace('az795974.cdn.gooten.com/cdn', `${BASE_CDN}/appassets`)
      .replace('az795974.cdn.gooten.com/img-manip-v2', `${BASE_CDN}/imgmanipv2`)
      .replace('az795974.cdn.gooten.com/img-manip', `${BASE_CDN}/imgmanip`)
      .replace('az795974.cdn.gooten.com', BASE_CDN)
      // NOTE: This is for Azure storage
      .replace('cdn.print.io', `${BASE_CDN}/appassets`)
      .replace('app-imgs.print.io', `${BASE_CDN}/appassets`)
      .replace('imgsrv.print.io/images', `${BASE_CDN}/appassets`)
      .replace('appassets.azureedge.net', `${BASE_CDN}/appassets`)
      .replace('az412349.cdn.gooten.com', `${BASE_CDN}/appassets`)
      .replace('az795372.cdn.gooten.com', `${BASE_CDN}/appassets`)
      .replace('az504546.cdn.gooten.com', `${BASE_CDN}/appassets`)
      .replace('imgr.azureedge.net', `${BASE_CDN}/appassets`)
      .replace('img-manip-resize.azureedge.net', `${BASE_CDN}/imgmanip`)
      .replace('gtnimgmanipstor.blob.core.windows.net', `${BASE_CDN}/imgmanip`)
      .replace('gtnimgmanipcdn.azureedge.net', `${BASE_CDN}/imgmanip`)
      // ImgManip v2
      .replace('gtnimgmanipstorv2.blob.core.windows.net', `${BASE_CDN}/imgmanipv2`)
      .replace('gtn-imgmanip-v2-cdn.azureedge.net', `${BASE_CDN}/imgmanipv2`)
      // NOTE: This is for S3 storage
      .replace('printio-widget-live.s3.amazonaws.com', `${BASE_CDN}/widget-live`)
      .replace('printio-widget-dev.s3.amazonaws.com', `${BASE_CDN}/widget-dev`)
      // Depricate printmee-imgsrv.cloudapp.net
      .replace('printmee-imgsrv.cloudapp.net/images', `${BASE_CDN}/printmee`);
};

const getImageResizerUrl = (url, w, h) => {
  if (!isOurCdn(url)) {
    return url;
  }
  return sanitizeCDNUrl(url).concat(`?w=${w}&h=${h}`);
};

const getProductImageResizerUrl = url => {
  return getImageResizerUrl(url, 612, 612);
};

const getEditorImageResizerUrl = url => {
  return getImageResizerUrl(url, 1000, 1000);
};

const getSmallPreviewImageResizerUrl = url => {
  return getImageResizerUrl(url, 200, 200);
};

const getOrderDetailsImageResizerUrl = url => {
  return getImageResizerUrl(url, 300, 300);
};

const extractImageNameFromUrl = url => {
  return url.substring(url.lastIndexOf('/') + 1).split('?')[0];
};

const base64ToBlob = (data, contentType, sliceSize) => {
  data = data.split(',')[1] || data;

  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const toWebSafeString = str =>
  str
    .toLowerCase()
    .replace(/\s/gi, '_')
    .replace(/-/gi, '_')
    .replace(/\//gi, '_')
    .replace(/\(/gi, '')
    .replace(/\)/gi, '');

// Because this module is used on gooten-js-preview which has gulp and older setup,
// we use old style exports here
exports.sanitizeCDNUrl = sanitizeCDNUrl;
exports.getImageResizerUrl = getImageResizerUrl;
exports.getProductImageResizerUrl = getProductImageResizerUrl;
exports.getEditorImageResizerUrl = getEditorImageResizerUrl;
exports.getSmallPreviewImageResizerUrl = getSmallPreviewImageResizerUrl;
exports.getOrderDetailsImageResizerUrl = getOrderDetailsImageResizerUrl;
exports.extractImageNameFromUrl = extractImageNameFromUrl;
exports.base64ToBlob = base64ToBlob;
exports.toWebSafeString = toWebSafeString;
