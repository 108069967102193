import React from 'react';
import './index.scss';

const SignUpInfo = props => {
  return (
    <div className="sign-up-info">
      <div className="info-header">You Sell, We Fulfill.</div>
      <div className="checklist-item">
        Competitive pricing, volume discounts and simple comprehensive shipping options.
      </div>
      <div className="checklist-item">
        Fastest growing catalog offers over 500 on-demand products with new products added monthly.
      </div>
      <div className="checklist-item">
        Our technology will route, process, produce and ship orders optimized for your end customer.
      </div>
      <div className="checklist-item">
        A global network offers localized production and the latest in print innovation &
        technology.
      </div>
      <div className="checklist-item">
        Trusted integrations with all major platforms. A Shopify Plus Certified App Partner and
        direct API integrations with global retailers.
      </div>
    </div>
  );
};

export default SignUpInfo;
