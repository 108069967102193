import React from 'react';
import './index.scss';

const defaultHeader = 'You Sell, We Fulfill.';

const defaultChecklist = [
  'Competitive pricing, volume discounts and simple comprehensive shipping options.',
  'Fastest growing catalog offers over 500 on-demand products with new products added monthly.',
  'Our technology will route, process, produce and ship orders optimized for your end customer.',
  'A global network offers localized production and the latest in print innovation & technology.',
  'Trusted integrations with all major platforms. A Shopify Plus Certified App Partner and direct API integrations with global retailers.'
];

const SignUpInfo = props => {
  const checklistItems = props.partnerData ? props.partnerData.checklist : defaultChecklist;
  return (
    <div className="sign-up-info">
      <div className="info-header">
        {props.partnerData ? props.partnerData.header : defaultHeader}
      </div>
      {checklistItems.map(item => (
        <div key={item} className="checklist-item">
          {item}
        </div>
      ))}
    </div>
  );
};

export default SignUpInfo;
