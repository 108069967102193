import { combineReducers } from 'redux-immutable';
import { fromJS, Map, List } from 'immutable';
import {
  CONFIGURE,
  MAXIMIZE_ON,
  MAXIMIZE_OFF,
  FULLSCREEN,
  FULLSCREEN_CURRENT_ITEMS,
  SELECT_ITEMS,
  DESELECT_ITEM,
  DESELECT_ALL_ITEMS,
  UPDATE_PREVIEW_DATA,
  UPDATE_PREVIEW_IMAGE_URL,
  UPDATE_GENERATION_STATE,
  UPDATE_PREVIEW_SIZE,
  UPDATE_BACKGROUNDS,
  REMOVE_ITEMS,
  SHOW_REMOVE_MODAL,
  RESTORE_ALL_ITEMS,
  RESET_NOTIFICATION_STATE,
  RESET_PREVIEWS,
  EDIT_MODE_ON,
  EDIT_MODE_OFF,
  SHOW_DOWNLOAD_MODAL,
  UPDATE_SMALL_PREVIEW_IMAGE_URL,
  RESTORE_LAST_DELETED_ITEMS,
  UPDATE_EDIT_STATE,
  CANCEL_EDIT_MOCKUPS,
  RESET_EDIT_STATE,
  SHOW_SHOPIFY_IMAGES_COUNT_MODAL,
  CANCEL_PREVIEWS_GENERATION,
  CANCEL_FULLSCREEN_GENERATION,
  UPDATE_HIGH_RES_GENERATION_STATE,
  UPDATE_GENERATION_ITEMS_COUNT,
  REMOVE_GENERATED_SKUS,
  ADD_GENERATED_SKU,
  SHOW_GENERATING_MODAL,
  UPDATE_DOWNLOAD_MODAL,
  UPDATE_ASSOCIATED_SKUS
} from './ProductPreviewActions';
import { PREVIEWS_PREPARE, EXCLUSIVE_MOCKUPS_PREPARE } from './../../store/actions/dataActions';
import { RESET } from '../../store/actions/globalActions';
import * as BackgroundSelectReducers from './atoms/SidePanel/BackgroundSelect/BackgroundSelectReducers';
import { noPreviewsThresholdNumber } from '../ImageUpload/ImageUploadSelectors';

// Reducer is a pure function
// DO NOT: Mutate its arguments;
// DO NOT: Perform side effects like API calls and routing transitions;
// DO NOT: Call non-pure functions, e.g. Date.now() or Math.random().

const maximizedInitialState = false;
const generationStateInitial = {
  state: 'SUCCESS',
  fullscreenState: 'SUCCESS',
  showLoadingModal: false,
  finishedSKUs: new Set()
};

const modalStateInitial = {
  removeItemsModal: {
    show: false,
    numberOfRemovedItems: 0
  },
  proPreviewsModal: {
    show: false
  },
  downloadModal: {
    show: false,
    current: 0,
    total: 0
  },
  shopifyImagesCountModal: {
    show: false,
    numberOfItems: 0
  },
  generatingModal: {
    show: false
  }
};

const previewDataInitialState = {
  items: [],
  removedItems: []
};

const editModeInitialState = {
  modeOn: false,
  savedState: {
    items: [],
    previewSize: {}
  }
};

const setImageUrlInItems = (
  type,
  items,
  sku,
  sId,
  dataIndex,
  imageUrl,
  previewSize,
  isFullscreen
) => {
  const skuIndex = items.findIndex(
    item =>
      item.get('sku') === sku && item.get('sId') === sId && item.get('dataIndex') === dataIndex
  );

  // NOTE: always regnerate previews for large number of variants
  // bcause we don't do intital preview renders for products with many variants
  const totalSKUS = () => {
    let total = 0;
    items.toJS().map(x => {
      if (x.associatedSkus && x.associatedSkus.length === 0) {
        total++;
      } else if (x.associatedSkus) {
        total = total + x.associatedSkus.length;
      }
    });
    return total;
  };
  const alwaysRegeneratePreview = totalSKUS() > noPreviewsThresholdNumber;

  if (skuIndex >= 0) {
    return type === 'previewImageUrl'
      ? isFullscreen
        ? items
            .setIn([skuIndex, 'fullscreenImageUrl'], imageUrl)
            .setIn([skuIndex, 'generateFullscreen'], false)
        : items
            .setIn([skuIndex, type], imageUrl)
            .setIn([skuIndex, 'generateHighRes'], false)
            .setIn([skuIndex, 'generatedHighResSize'], previewSize)
      : items
          .setIn([skuIndex, type], imageUrl)
          .setIn([skuIndex, 'generatePreview'], alwaysRegeneratePreview);
  } else {
    return items;
  }
};

const updateExclusiveMockupUrls = (items, payload) => {
  const usedUrls = new Set();
  const updatedItems = items.map(item => {
    // Check here if item is exclusive mockup (pro preview or/and external mockup)
    if (item.isExclusiveMockup) {
      const sku = payload.find(
        obj => obj.sku.toLowerCase() === item.sku.toLowerCase() && obj.dataIndex === item.dataIndex
      );

      let previewImageUrls = [];

      if (item.externalMockupId && item.externalMockupId.length > 0) {
        // Get all mockup URLs that match the externalMockupId
        const matchingIndices = sku.externalMockup.mockups
          .map((mockup, index) => mockup.ID === item.externalMockupId ? index : -1)
          .filter(index => index !== -1);
        
        previewImageUrls = matchingIndices.map(index => sku.externalMockup.imageUrls[index]);
      } else if (item.sId && item.sId.length > 0) {
        // Get all scene URLs that match the sId
        const matchingIndices = sku.proPreview.scenes
          .map((scene, index) => scene.name === item.sId ? index : -1)
          .filter(index => index !== -1);
        
        previewImageUrls = matchingIndices.map(index => sku.proPreview.imageUrls[index]);
      }

      // Filter out empty strings and find first unused URL
      const availableUrls = previewImageUrls.filter(url => url);
      const uniqueUrl = availableUrls.find(url => !usedUrls.has(url)) || availableUrls[0] || '';
      
      if (uniqueUrl) {
        usedUrls.add(uniqueUrl);
      }

      return {
        ...item,
        previewImageUrl: uniqueUrl
      };
    } else {
      return {
        ...item
      };
    }
  });
  return fromJS(updatedItems);
};

const updatePreviewUrls = (items, payload) => {
  items = items.map(item => {
    if (!item.isExclusiveMockup) {
      const previewImageUrl = payload.items
        .find(
          obj =>
            obj.sku.toLowerCase() === item.sku.toLowerCase() && obj.dataIndex === item.dataIndex
        )
        .spaces.find(space => space.id === item.sId).previewImgUrl;
      return {
        ...item,
        previewImageUrl,
        generateHighRes: false,
        generatedHighResSize: payload.previewSize
      };
    } else {
      return {
        ...item
      };
    }
  });
  return fromJS(items);
};

export const previewConfigReducer = (state = new Map(), action) => {
  switch (action.type) {
    case CONFIGURE: {
      return fromJS(action.payload);
    }
    default:
      return state;
  }
};

export const maximizedReducer = (state = maximizedInitialState, action) => {
  switch (action.type) {
    case MAXIMIZE_ON:
      return true;
    case MAXIMIZE_OFF:
      return false;
    default:
      return state;
  }
};

export const editModeReducer = (state = fromJS(editModeInitialState), action) => {
  switch (action.type) {
    case EDIT_MODE_ON:
      return state && state.set('modeOn', true);

    case EDIT_MODE_OFF:
      return state && state.set('modeOn', false);

    case UPDATE_EDIT_STATE:
      return (
        state &&
        state
          .setIn(['savedState', 'items'], fromJS(action.payload.items))
          .setIn(['savedState', 'previewSize'], fromJS(action.payload.previewSize))
      );

    case RESET_EDIT_STATE:
      return state && state.setIn(['savedState', 'items'], new List());

    case UPDATE_SMALL_PREVIEW_IMAGE_URL:
      return state.updateIn(['savedState', 'items'], items =>
        action.payload.hasBcgChanged
          ? items
          : setImageUrlInItems(
              'smallPreviewImageUrl',
              items,
              action.payload.sku,
              action.payload.sId,
              action.payload.dataIndex,
              action.payload.imageUrl
            )
      );

    case EXCLUSIVE_MOCKUPS_PREPARE.SUCCESS:
      let current = state.toJS();
      if (current.savedState.items.length) {
        current.savedState.items = updateExclusiveMockupUrls(
          current.savedState.items,
          action.payload
        );
        return fromJS(current);
      }
      return state;

    default:
      return state;
  }
};

export const fullScreenPreviewReducer = (state = new Map(), action) => {
  switch (action.type) {
    case FULLSCREEN:
      return state && state.set('enabled', action.payload);

    case FULLSCREEN_CURRENT_ITEMS:
      return state && state.set('currentItem', fromJS(action.payload.data[0]));

    case UPDATE_PREVIEW_IMAGE_URL:
      return state.update('currentItem', item => {
        if (
          item &&
          item.get('sku') === action.payload.sku &&
          item.get('sId') === action.payload.sId &&
          item.get('dataIndex') === action.payload.dataIndex &&
          action.payload.isFullscreen
        ) {
          return item
            .set('fullscreenImageUrl', action.payload.previewImageUrl)
            .set('generateFullscreen', false);
        }
        return item;
      });

    case RESET_PREVIEWS:
      return new Map();

    default:
      return state;
  }
};

export const selectedItemsReducer = (state = new List(), action) => {
  let current = state.toJS();

  switch (action.type) {
    case SELECT_ITEMS: {
      if (action.payload.every(x => current.includes(x))) {
        return state;
      } else {
        action.payload.forEach(x => current.push(x));
      }
      return fromJS(current);
    }

    case DESELECT_ITEM: {
      const itemToDeselect = current.find(
        item =>
          item.sku.toLowerCase() === action.payload.sku.toLowerCase() &&
          item.sId === action.payload.sId &&
          item.index === action.payload.index
      );
      return fromJS(current.filter(item => item !== itemToDeselect));
    }

    case DESELECT_ALL_ITEMS:
    case REMOVE_ITEMS:
    case RESET_PREVIEWS:
      return new List();

    case UPDATE_PREVIEW_IMAGE_URL:
      return state.update(items =>
        setImageUrlInItems(
          'previewImageUrl',
          items,
          action.payload.sku,
          action.payload.sId,
          action.payload.dataIndex,
          action.payload.previewImageUrl,
          action.payload.previewSize,
          action.payload.isFullscreen
        )
      );

    case UPDATE_SMALL_PREVIEW_IMAGE_URL:
      return state.update(items =>
        setImageUrlInItems(
          'smallPreviewImageUrl',
          items,
          action.payload.sku,
          action.payload.sId,
          action.payload.dataIndex,
          action.payload.imageUrl
        )
      );

    case UPDATE_BACKGROUNDS:
      return state.update(items => {
        action.payload.forEach(obj => {
          const itemIndex = items.findIndex(
            item =>
              item.get('sku') === obj.sku &&
              item.get('sId') === obj.sId &&
              item.get('dataIndex') === obj.dataIndex
          );
          items = items
            .set(itemIndex, fromJS(obj))
            .setIn([itemIndex, 'generateHighRes'], true)
            .setIn([itemIndex, 'generateFullscreen'], true);
        });
        return items;
      });

    case EXCLUSIVE_MOCKUPS_PREPARE.SUCCESS: {
      return updateExclusiveMockupUrls(current, action.payload);
    }

    // case PREVIEWS_PREPARE.SUCCESS: {
    //   let items = state.toJS()
    //   return updatePreviewUrls(items, action.payload)
    // }

    default:
      return state;
  }
};

export const previewDataReducer = (state = fromJS(previewDataInitialState), action) => {
  switch (action.type) {
    case UPDATE_PREVIEW_DATA:
      return state && state.set('items', fromJS(action.payload));

    case UPDATE_PREVIEW_IMAGE_URL:
      return state.updateIn(['items'], items =>
        setImageUrlInItems(
          'previewImageUrl',
          items,
          action.payload.sku,
          action.payload.sId,
          action.payload.dataIndex,
          action.payload.previewImageUrl,
          action.payload.previewSize,
          action.payload.isFullscreen
        )
      );

    case UPDATE_ASSOCIATED_SKUS: {
      let current = state.toJS();

      const associatedItems = current.items.filter(x =>
        x.associatedSkus.find(z => z.sku === action.payload.selected.sku)
      );
      const associatedIndex = associatedItems[0].associatedSkus.find(
        x => x.sku === action.payload.selected.sku
      ).dataIndex;

      associatedItems.forEach(associatedItem => {
        // remove associated sku from old previewImage
        const associatedItemIndex = current.items.indexOf(associatedItem);

        current.items[associatedItemIndex].associatedSkus = current.items[
          associatedItemIndex
        ].associatedSkus.filter(x => x.sku !== action.payload.selected.sku);

        // add it to the correct preview image as an associated SKU
        const newPreviewAttachedIndex = current.items.findIndex(
          x => x.previewImageUrl === action.payload.newUrl
        );

        current.items[newPreviewAttachedIndex].associatedSkus = current.items[
          newPreviewAttachedIndex
        ].associatedSkus.concat([
          {
            sku: action.payload.selected.sku,
            dataIndex: associatedIndex
          }
        ]);
      });

      return fromJS(current);
    }

    case UPDATE_SMALL_PREVIEW_IMAGE_URL:
      return state.updateIn(['items'], items =>
        setImageUrlInItems(
          'smallPreviewImageUrl',
          items,
          action.payload.sku,
          action.payload.sId,
          action.payload.dataIndex,
          action.payload.imageUrl
        )
      );

    case UPDATE_PREVIEW_SIZE:
      return state && state.set('previewSize', fromJS(action.payload));

    case UPDATE_BACKGROUNDS: {
      return state.updateIn(['items'], items => {
        action.payload.forEach(obj => {
          const itemIndex = items.findIndex(
            item =>
              item.get('sku') === obj.sku &&
              item.get('sId') === obj.sId &&
              item.get('dataIndex') === obj.dataIndex
          );
          items = items
            .set(itemIndex, fromJS(obj))
            .setIn([itemIndex, 'generateHighRes'], true)
            .setIn([itemIndex, 'generateFullscreen'], true);
        });
        return items;
      });
    }

    case PREVIEWS_PREPARE.SUCCESS: {
      let current = state.toJS();
      current.items = updatePreviewUrls(current.items, action.payload);
      return fromJS(current);
    }

    case EXCLUSIVE_MOCKUPS_PREPARE.SUCCESS: {
      let current = state.toJS();
      current.items = updateExclusiveMockupUrls(current.items, action.payload);
      return fromJS(current);
    }

    case REMOVE_ITEMS: {
      let current = state.toJS();

      // first set restoreIndex, and then update current restore indexes
      const itemsToRemove = action.payload.map(item => ({ ...item, restoreIndex: 0 }));
      current.removedItems = current.removedItems
        .map(item => ({ ...item, restoreIndex: item.restoreIndex + 1 }))
        .concat(itemsToRemove);

      // remove items
      current.items = current.items.filter(
        item =>
          !itemsToRemove.some(
            s =>
              s.sku.toLowerCase() === item.sku.toLowerCase() &&
              s.sId === item.sId &&
              s.index === item.index
          )
      );

      // update removedItems with new restore indexes
      return fromJS(current);
    }

    case RESTORE_ALL_ITEMS: {
      return state.setIn(['items'], state.get('removedItems')).set('removedItems', new List());
    }

    case RESTORE_LAST_DELETED_ITEMS: {
      let current = state.toJS();

      // get items with restoreIndex === 0
      const lastDeletedItems = current.removedItems.filter(item => item.restoreIndex === 0);

      // remove items with restoreIndex === 0 from 'removedItems', and decrease removeIndex by 1
      current.removedItems = current.removedItems
        .filter(
          item =>
            !lastDeletedItems.some(
              s =>
                s.sku.toLowerCase() === item.sku.toLowerCase() &&
                s.sId === item.sId &&
                s.index === item.index
            )
        )
        .map(item => ({ ...item, restoreIndex: item.restoreIndex - 1 }));

      // put 'lastDeletedSkus' back to 'items' if it's not already there
      lastDeletedItems.forEach(item => {
        if (
          !current.items.filter(
            c =>
              c.sku.toLowerCase() === item.sku.toLowerCase() &&
              c.sId === item.sId &&
              c.index === item.index
          ).length
        ) {
          current.items = current.items.concat([item]);
        }
      });
      return fromJS(current);
    }

    case RESET_PREVIEWS:
      return state && state.set('removedItems', new List());

    case CANCEL_EDIT_MOCKUPS:
      return (
        state &&
        state
          .set('items', fromJS(action.payload.savedState.items))
          .set('previewSize', fromJS(action.payload.savedState.previewSize))
          .set(
            'removedItems',
            !action.payload.savedState.items.length
              ? state.get('items').size
                ? state.get('items')
                : state.get('removedItems')
              : state
                  .get('removedItems')
                  .filterNot(rItem =>
                    action.payload.savedState.items.some(
                      item =>
                        item.dataIndex === rItem.get('dataIndex') &&
                        item.index === rItem.get('index') &&
                        item.sku.toLowerCase() === rItem.get('sku').toLowerCase()
                    )
                  )
          )
      );

    default:
      return state;
  }
};

export const generationStateReducer = (state = fromJS(generationStateInitial), action) => {
  switch (action.type) {
    case UPDATE_GENERATION_STATE:
      return state.set('state', action.payload);

    case UPDATE_HIGH_RES_GENERATION_STATE:
      return state.set('fullscreenState', action.payload);

    case REMOVE_GENERATED_SKUS:
      return state.update('finishedSKUs', fskus => {
        action.payload.forEach(a => fskus.delete(a));
        return fskus;
      });

    case ADD_GENERATED_SKU:
      return state.update('finishedSKUs', fskus => fskus.add(action.payload));

    case UPDATE_GENERATION_ITEMS_COUNT:
      return state.set('previewGeneration', fromJS(action.payload));

    case PREVIEWS_PREPARE.ASYNC:
      return state.set('state', 'WORKING');

    case PREVIEWS_PREPARE.SUCCESS:
    case EXCLUSIVE_MOCKUPS_PREPARE.SUCCESS:
      return state.set('state', 'SUCCESS').delete('previewGeneration');

    case PREVIEWS_PREPARE.FAIL:
    case EXCLUSIVE_MOCKUPS_PREPARE.FAIL:
      return state.set('state', 'FAIL').delete('previewGeneration');

    case PREVIEWS_PREPARE.CANCEL:
    case EXCLUSIVE_MOCKUPS_PREPARE.CANCEL:
    case CANCEL_PREVIEWS_GENERATION:
      return state.set('state', 'CANCELLED').delete('previewGeneration');

    case CANCEL_FULLSCREEN_GENERATION:
      return state.set('fullscreenState', 'CANCELLED');

    default:
      return state;
  }
};

export const modalsReducer = (state = fromJS(modalStateInitial), action) => {
  switch (action.type) {
    case SHOW_REMOVE_MODAL:
      return state && state.set('showRemoveModal', action.payload);

    case SHOW_SHOPIFY_IMAGES_COUNT_MODAL:
      return state && state.setIn(['shopifyImagesCountModal', 'show'], true);

    case REMOVE_ITEMS:
      return (
        state &&
        state.set(
          'removeItemsModal',
          fromJS({ show: true, numberOfRemovedItems: action.payload.length })
        )
      );

    case RESET_NOTIFICATION_STATE:
      return (
        state &&
        state
          .setIn(['removeItemsModal', 'show'], false)
          .setIn(['shopifyImagesCountModal', 'show'], false)
      );

    case EXCLUSIVE_MOCKUPS_PREPARE.ASYNC:
      return state && state.setIn(['proPreviewsModal', 'show'], true);

    case EXCLUSIVE_MOCKUPS_PREPARE.SUCCESS:
    case EXCLUSIVE_MOCKUPS_PREPARE.FAIL:
      return state && state.setIn(['proPreviewsModal', 'show'], false);

    case SHOW_DOWNLOAD_MODAL:
      return state && state.setIn(['downloadModal', 'show'], action.payload);

    case UPDATE_DOWNLOAD_MODAL:
      return (
        state &&
        state
          .setIn(['downloadModal', 'current'], action.payload.current)
          .setIn(['downloadModal', 'total'], action.payload.total)
      );

    case SHOW_GENERATING_MODAL:
      return state && state.setIn(['generatingModal', 'show'], action.payload);

    default:
      return state;
  }
};

const combinedReducer = combineReducers({
  config: previewConfigReducer,
  data: previewDataReducer,
  maximized: maximizedReducer,
  editMode: editModeReducer,
  background: BackgroundSelectReducers.backgroundReducer,
  fullScreenPreview: fullScreenPreviewReducer,
  selectedItems: selectedItemsReducer,
  generationState: generationStateReducer,
  modals: modalsReducer
});

// make root reducer
export default (state, action) => {
  if (action.type === RESET) {
    state = undefined;
  }

  return combinedReducer(state, action);
};
